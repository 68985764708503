import produce from 'immer';
import { useMemo, useState } from 'react';
import type {
  ItemsStoreProps, ItemsStore, ItemByValue, Items, Item,
} from './types';

const getItemsStore = (items: Items) => {
  const itemsStore: ItemsStore = {
    /**
     * Flat list of selectable items
     */
    items: [],

    /**
     * Normalized store for all UI elements to be rendered
     */
    listStore: {
      allValues: [],
      byValue: {},
    },
  };

  const recursiveFlat = (recursiveList: Items) => {
    recursiveList.forEach((item: Item) => {
      const { value, label, choices, depth, parent_id: parentId, parent_cat: parentCat } = item;
      const isParent = !depth && typeof choices !== 'undefined' && choices.length > 0;

      itemsStore.items.push({ label, value });
      itemsStore.listStore.allValues.push(value);
      itemsStore.listStore.byValue[value] = {
        label,
        value,
        depth,
        parent_id: parentId,
        parent_cat: parentCat,
        isHeader: isParent,
        colapsed: false,
        choices,
      };

      if (choices && choices.length) {
        recursiveFlat(choices);
      }
    });
  };

  recursiveFlat(items);

  return itemsStore;
};

export const useItemsStore = ({ items, selectedItems }: ItemsStoreProps) => {
  const [itemsStore, setItemsStore] = useState(getItemsStore(items));
  const [inputValue, setInputValue] = useState('');

  const visibleItems = useMemo(() => {
    const searchVal = inputValue.toLocaleLowerCase();

    return itemsStore.items.filter((filteredItem) => filteredItem.label.toLowerCase().includes(searchVal));
  }, [inputValue]);

  const getItemByValue = (itemValue: string | number) => itemsStore.listStore.byValue[itemValue];

  // utility for looping through everything meant to render in the select
  const mapSelectList = (callback: (item: ItemByValue, index: number) => void): any => (
    visibleItems.map(({ value }, index) => callback(getItemByValue(value), index))
  );

  const isSelected = (id: string | number) => selectedItems.some((selected) => selected.value === id);

  const findParent = (id: string | number, filterSelected: boolean) => {
    let item = getItemByValue(id);
    let parent = getItemByValue(item.parent_id) || getItemByValue(item.parent_cat!);
    let hasParent = parent && parent.parent_id !== null && parent.parent_id > 0;

    while (hasParent && (filterSelected ? !isSelected(parent.value) : true)) {
      item = getItemByValue(parent.value);
      parent = getItemByValue(item.parent_id);
      hasParent = parent && parent.parent_id !== null && parent.parent_id > 0;
    }

    return parent;
  };

  const isDisabled = (id: string | number) => {
    // No need to check for specific items because parents can't be disabled
    const parent = findParent(id, true);

    if (parent) {
      return isSelected(parent.value);
    }

    return false;
  };

  const isColapsed = (id: string | number) => {
    const item = getItemByValue(id);

    // If its a header item, find it directly
    if (item.isHeader) {
      return item.colapsed;
    }

    // If its a regular item then find the parent item and check if its clopased or not
    const parent = findParent(id, false);

    return parent ? parent.colapsed : false;
  };

  const toggleColapse = (id: string | number) => {
    setItemsStore(produce((draftState) => {
      draftState.listStore.byValue[id].colapsed = !draftState.listStore.byValue[id].colapsed;
    }));
  };

  return {
    downshiftItems: visibleItems,
    getItemByValue,
    mapSelectList,
    isSelected,
    isDisabled,
    isColapsed,
    toggleColapse,
    listStore: itemsStore.listStore,
    inputValue,
    setInputValue,
  };
};
