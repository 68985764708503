import { object as ST, string, optional } from 'superstruct';
import { fileList, required, requiredDependsOn } from '@/utils/inputValidation';

// Hooks
import { usePostSupport } from './services/postSupport';

// Types
import type { OnSubmitFunc } from '@/types/forms';
import type { SupportFormFields } from './types';
import type { FetchError } from '@/types/api';

export const useSupportForm = ({
  onSuccess,
  onError,
}: { onSuccess?: VoidFunction; onError?: (error: FetchError) => void; }) => {
  const { isPending: isPostingRfi, mutate: sendEmail } = usePostSupport();

  const onSubmit: OnSubmitFunc<SupportFormFields> = (rawFormData, setGeneralError, setSuccessMessage) => {
    const formData = new FormData();

    Object.entries(rawFormData).forEach(([key, value]) => {
      if (value) {
        if (key === 'attachment') {
          [...rawFormData.attachment].forEach(((att) => { formData.append(key, att); }));
        } else {
          formData.append(key, value);
        }
      }
    });

    sendEmail(formData, {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
          return;
        }

        setSuccessMessage('Request sent succesfully');
      },
      onError: (error) => {
        if (onError) {
          onError(error);
          return;
        }

        setGeneralError(error);
      },
    });
  };

  const schema = ST({
    subject: required(string(), 'Subject is required'),
    otherSubject: requiredDependsOn('subject', 'Other'),
    description: required(string(), 'Description is required'),
    due_date: required(string(), 'Due Date is required'),
    attachment: optional(fileList()),
    to: required(string(), ''),
    ticket_id: optional(string()),
    subclient_id: optional(string()),
  });

  return {
    isPostingRfi,
    schema,
    onSubmit,
  };
};
