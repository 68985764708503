import { createContext, useContext } from 'react';
import type { UseComboboxReturnValue } from 'downshift';

import { useItemsStore } from './useItemsStore';

import type { NormalizedItem } from './types';

interface SelectContextValue {
  combobox: UseComboboxReturnValue<NormalizedItem>;
  selectedItems: NormalizedItem[];
  removeSelectedItem: (item: NormalizedItem) => void;
  itemsStore: ReturnType<typeof useItemsStore>;
  dropdownProps: any; // There is no type in downshift for this
}

export const SelectContext = createContext<SelectContextValue | null>(null);

export const useSelect = () => {
  const selectContext = useContext(SelectContext);

  if (!selectContext) {
    throw new Error('SelectContext is not initialized. This hook must be used inside a SelectContextProvider');
  }

  return selectContext;
};
