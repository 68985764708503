import { Group } from '@mantine/core';

import Chevrons from '@/assets/icons/sidebar/chevrons.svg';

import navBarClasses from './Navbar.module.css';

interface ExpandProps {
  toggleDesktop: () => void;
  desktopOpened: boolean;
}

const Expand = ({ toggleDesktop, desktopOpened }: ExpandProps) => (
  <Group
    align="center"
    wrap="nowrap"
    aria-label={desktopOpened ? 'Collapse' : 'Expand'}
    onClick={toggleDesktop}
  >
    <span className={`${navBarClasses.toggleIcon} ${desktopOpened ? navBarClasses.expanded : ''}`}>
      <Chevrons />
    </span>

    <span className={`${navBarClasses.toggleLabel} ${desktopOpened ? navBarClasses.expanded : ''}`}>
      Collapse
    </span>
  </Group>
);

export default Expand;
