import styled from 'styled-components';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object as ST, size, string } from 'superstruct';

// Components
import Input from '@/components/content/form/components/Input';
import Form from '@/components/content/form/Form';

// Hooks
import { useQRCode } from '../hooks/useQrCode';

// Misc
import { required } from '@/utils/inputValidation';
import { TFAData } from '../hooks/useTfa';

import type { OnSubmitFunc } from '@/types/forms';
import type { TFAFormFields } from '../types';
import { TFAFormLabels } from '../types';

interface TwoFAModalProps {
  data?: TFAData
  enabled: boolean;
  tfaAction: OnSubmitFunc<TFAFormFields>;
  disableAction: boolean;
}

// Styles
const RTE = styled.div`
  margin-top: 25px;

  > * + * {
    margin-top: 15px;
  }

  span {
    display: block;
  }

  h2 {
    font-size: 20px;
  }
`;

const TwoFAModal = ({ data, enabled, tfaAction, disableAction }: TwoFAModalProps) => {
  const QRCode = useQRCode();

  const schema = ST({
    code: required(size(string(), 6, 6), 'Code is required'),
  });

  return (
    <>
      {!enabled && (
        <RTE>
          <>
            <p>Begin by installing a Two-Factor Authentication app on your device:</p>

            <h2><b>Option 1: Type this secret into your app</b></h2>
            <span>
              <b>Secret: </b>{data?.secret}
            </span>
            <h2><b>Option 2: Scan the QR code with your app</b></h2>

            <QRCode text={data?.uri || ''} options={{ margin: 1 }} />
          </>
        </RTE>
      )}

      <Form<TFAFormFields>
        onSubmit={tfaAction}
        options={{ resolver: superstructResolver(schema) }}
        submitButton={{
          text: 'Submit',
          disabled: disableAction,
        }}
        name="tfa-form"
        fields={TFAFormLabels}
      >
        {({ register, formState: { errors } }) => (
          <Input<TFAFormFields>
            register={register}
            type="text"
            name="code"
            placeholder="Type generated code here"
            label={TFAFormLabels.code}
            error={errors?.code}
            autoComplete="off"
            required
          />
        )}
      </Form>
    </>
  );
};

export default TwoFAModal;
