import { Group } from '@mantine/core';
import { useMemoizedFn } from 'ahooks';

import MetaContainer from './MetaContainer';
import Title from '@/components/content/title/Title';

import type { FiltersMeta } from '@/types/meta';
import type { MetaCategories } from '../types';
import type { MetaStore } from '@/hooks/useMetaStore';

import classes from './Meta.module.css';

interface MetaSectionProps {
  metaCategories: MetaCategories;
  store: MetaStore;
}

const MetaSection = ({ metaCategories, store }: MetaSectionProps) => {
  const renderValues = Object.values(metaCategories);

  const onClear = useMemoizedFn((meta: FiltersMeta) => {
    const toClear = meta.map((item) => item.name);

    store.filters.clear(toClear);
  });

  return (
    <div>
      {renderValues.map((cat) => (
        <div key={cat.name} className={classes.section}>
          <Group align="baseline">
            <Title>
              <span>{cat.label}</span>
            </Title>

            <button
              type="button"
              onClick={() => onClear(cat.meta)}
              className={`${classes.clear} ${classes.all}`}
            >
              Clear
            </button>
          </Group>

          <MetaContainer meta={cat.meta} store={store} />
        </div>
      ))}
    </div>
  );
};

export default MetaSection;
