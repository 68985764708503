import styled, { css } from 'styled-components';

import IconButton from '@/components/content/button/IconButton';
import ChevronDown from '@/assets/icons/content/chevron-down.svg';
import { CheckboxStyled } from '@/components/content/form/components/Checkbox';

import type { ItemRenderProps } from './types';

const Header = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LabelBase = styled.label`
  padding: 8px;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  color:var(--quo-blue-75);
`;

const highlightedStyle = css`
  color: var(--quo-power-75);
`;

const Label = styled(LabelBase) <{ $highlighted: boolean; }>`
  ${(props) => props.$highlighted && highlightedStyle}
`;

const HeaderItem = ({
  item,
  highlighted,
  selected,
  itemProps,
  toggleColapse,
}: ItemRenderProps) => (
  <Header>
    <Label $highlighted={highlighted} {...itemProps}>
      <CheckboxStyled
        type="checkbox"
        checked={!!selected}
        onChange={() => null}
        value={item.value}
        id={String(item.value)}
        name={String(item.value)}
      />
      {item.label}
    </Label>

    <IconButton
      icon={<ChevronDown />}
      onClick={() => toggleColapse(item.value)}
      label="Expand"
    />
  </Header>
);

export default HeaderItem;
