import styled from 'styled-components';

import Button from '@/components/content/button/Button';

import LogoIcon from '@/assets/icons/logo.svg';
import LogoNameIcon from '@/assets/icons/logo-name.svg';

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TimeoutWrapper = styled(Flex)`
  flex-direction: column;
  gap: 50px;
  height: 100vh;
  padding: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    padding: 40px;
  }
`;

const LogoWrapper = styled(Flex)`
  gap: 40px;
`;

const Logo = styled(LogoIcon)`
  width: 100px;
`;

const LogoName = styled(LogoNameIcon)`
  width: 100%;
`;

const TextWrapper = styled.div`
  padding: 20px;
  width: 100%;
  text-align: center;

  p {
    font-size: 22px;
  }

  button {
    width: 100%;
    margin-top: 25px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    width: 50%;
    padding: 40px;

    button {
      width: 40%;
    }
  }
`;

const Timeout = () => {
  const redirectLogin = () => {
    window.location.replace('/login');
  };

  return (
    <TimeoutWrapper>
      <LogoWrapper>
        <Logo />
        <LogoName />
      </LogoWrapper>

      <TextWrapper>
        <p>
          Your session expired due to inactivity. Please login again to keep using Mercury features.
        </p>

        <Button onClick={redirectLogin}>
          Login Again
        </Button>
      </TextWrapper>

    </TimeoutWrapper>
  );
};

export default Timeout;
