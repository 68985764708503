import { useEffect, useState } from 'react';

import Button from '@/components/content/button/Button';
import News from '@/features/news/News';
import TrialsModal from './TrialsModal';
import { NEWS } from '@/constants/text/english';

import { useModal } from '@/hooks/useModal';
import { hasUnreadItems, setReadItems } from '@/features/news/utils';
import { hasTrials, setTrials } from '@/utils/hasTrials';
import { useAuth } from '@/hooks/useAuth';

import type { SubscriptionsTrials } from '@/types/mercury-data-types';

const initialModals = (trials: Array<SubscriptionsTrials>) => {
  const modals = [];

  if (hasUnreadItems()) {
    modals.push({
      content: <News newsItem={NEWS[0]} />,
      onClose: () => setReadItems(),
    });
  }

  if (hasTrials(trials)) {
    modals.push({
      content: <TrialsModal />,
      onClose: setTrials,
    });
  }

  return modals;
};

const InitialModals = () => {
  const { userInfo } = useAuth();

  const [modals, setContent] = useState(initialModals(userInfo.entitlements_trial));
  const dequeue = () => {
    const newQueue = [...modals];
    newQueue.shift();
    setContent(newQueue);
  };

  const { Modal: MercuryModal, openModal, modalProps, closeModal } = useModal();

  useEffect(() => {
    if (modals.length > 0) {
      openModal();
    }
  }, []);

  const currentModal = modals[0];

  const onClose = () => {
    currentModal.onClose(userInfo.entitlements_trial);

    if (modals.length > 1) {
      dequeue();
      return;
    }

    closeModal();
  };

  if (!currentModal) return null;

  return (
    <>
      <MercuryModal {...modalProps} size="500px" onClose={onClose}>
        {modals[0].content}

        <Button onClick={onClose}>Close</Button>
      </MercuryModal>
    </>
  );
};

export default InitialModals;
