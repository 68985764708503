import {
  type PropsWithChildren, type ElementType, type ComponentPropsWithoutRef, forwardRef, Ref,
} from 'react';

import ExternalLink from '@/assets/icons/content/external-link.svg';

import classes from './Link.module.css';

export type LinkV2Props<C extends ElementType> = {
  as?: C;
  highlight?: boolean;
  icon?: ElementType;
  renderAsInternal?: boolean;
} & PropsWithChildren & ComponentPropsWithoutRef<C>;

const Linkv2 = forwardRef(<C extends ElementType = 'a'>(
  { as, children, highlight, icon: Icon, renderAsInternal, ...attrs }: LinkV2Props<C>,
  ref: Ref<HTMLAnchorElement>,
) => {
  const isExternal = attrs.href && attrs.href.startsWith('http');
  const Component = as || 'a';

  if (isExternal && !renderAsInternal) {
    return (
      <span className={classes.externalWrapper}>
        {children}

        <a
          {...attrs}
          target="_blank"
          rel="noreferrer"
          className={classes.external}
        >
          <ExternalLink />
        </a>
      </span>
    );
  }

  return (
    <Component
      className={`${classes.link} ${classes.regular} ${highlight ? classes.highlight : ''}`}
      ref={ref}
      {...attrs}
    >
      {Icon ? <Icon /> : children}
    </Component>
  );
});

export default Linkv2;
