import styled from 'styled-components';
import { inputStyles } from './components/Input';

export const IconButton = styled.button`
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  flex: 1;
  color: var(--content-font);

  &::placeholder {
    opacity: 0.6;
  }
`;

export const InputLikeContainer = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: inherit;

  ${inputStyles}

  ${(props) => props.$disabled && `
    background-color: var(--item-bg);
    opacity: 0.7;
  `}
`;
