import type { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Input, IconButton, InputLikeContainer } from '../../form.styles';

import { useSelect } from './SelectContext';
import ChevronDown from '@/assets/icons/content/chevron-down.svg';

const ComboboxContainer = styled.div`
  position: relative;

  form & {
    width: 100%;
  }
`;

const Combobox = ({ placeholder, children }: PropsWithChildren<{ placeholder: string; }>) => {
  const {
    combobox: { getInputProps, getToggleButtonProps },
    dropdownProps,
  } = useSelect();

  return (
    <ComboboxContainer>
      <InputLikeContainer>
        <Input placeholder={`Select ${placeholder}`} {...getInputProps(dropdownProps)} />

        <IconButton type="button" {...getToggleButtonProps()}>
          <ChevronDown />
        </IconButton>
      </InputLikeContainer>

      {children}
    </ComboboxContainer>
  );
};

export default Combobox;
