import { useContext } from 'react';

import { ThemeContext } from '@/context/ThemeContext';

export const useTheme = () => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error('ThemeContext is not initialized. This hook must be used inside a Provider');
  }

  return themeContext;
};
