import { useAuth } from '@/hooks/useAuth';
import { postMutation } from '@/lib/react-query/mutate';

export const usePostSupport = () => {
  const { userInfo: { user_id: userId } } = useAuth();

  return postMutation({
    endpoint: `mercury/rfi/${userId}`,
    json: false,
    requestOptions: {
      error: 'There was a problem with the request, please try again',
      contentType: 'multipart/form-data; boundary=----requestforinformation',
    },
  });
};
