/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import type { FieldError } from 'react-hook-form';
import type { Input as InputProps } from '@/types/forms';

// Components
import InputWrapper from './InputWrapper';

export const inputStyles = css`
  width: 100%;
  outline: none;
  font-size: 14px;
  color: var(--content-font);
  border: 1px solid var(--default-border);
  border-radius: var(--border-radius);
  height: 35px;
  padding: 5px 3px 5px 12px;
`;

const InputBase = styled.input`
  ${inputStyles}

  &:read-only {
    background-color: var(--item-bg);
    opacity: 0.7;
  }
`;

const errorStyle = css`
  border-bottom: 3px solid var(--quo-power-75);
`;

export const InputStyled = styled(InputBase) <{ $error?: FieldError }>`
  ${(props) => props.$error && errorStyle}
`;

export const Info = styled.span`
  font-size: 12px;
  color: var(--quo-grey-75);
  display: block;
  margin-top: 10px;
`;

const Input = <TFormValues extends Record<string, unknown>>({
  register,
  formOptions = {},
  label,
  required,
  layout,
  error,
  info,
  link,
  displayError,
  hidden,
  ...inputProps
}: InputProps<TFormValues>) => {
  const aria = info ? { 'aria-describedby': `addl-${inputProps.name}` } : {};

  return (
    <InputWrapper
      label={{ for: inputProps.name, text: label, required }}
      layout={layout}
      display={!hidden}
      displayError={displayError}
      error={error}
      link={link}
    >
      <div style={{ width: '100%' }}>
        <InputStyled
          {...register(inputProps.name, formOptions)}
          id={inputProps.name}
          aria-required={required ? 'true' : 'false'}
          aria-invalid={error ? 'true' : 'false'}
          $error={error}
          {...aria}
          {...inputProps}
        />

        {info && <Info id={`addl-${inputProps.name}`}>{info}</Info>}
      </div>
    </InputWrapper>
  );
};

export default Input;
