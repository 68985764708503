import { type ReactNode, forwardRef } from 'react';
import Tippy, { type TippyProps } from '@tippyjs/react';
import { followCursor } from 'tippy.js';

import classes from './Tooltip.module.css';

type TooltipProps = {
  title: ReactNode;
  customContent?: never;
  children?: any;
} & Partial<TippyProps> | {
  title?: never;
  customContent: ReactNode;
  children?: any;
} & Partial<TippyProps>;

// Ref is any because the types from the tippy and fowardRef are different and collapse
const Tooltip = forwardRef((
  { children, title, customContent, disabled, ...tippyProps }: TooltipProps,
  ref: any,
) => (
  <>
    {disabled ? (
      children
    ) : (
      <Tippy
        duration={0}
        animation="sift-away"
        content={(
          customContent || (
            <div id="tooltip" role="tooltip" className={classes.tooltip}>
              {title}
            </div>
          )
        )}
        reference={ref || null}
        plugins={[followCursor]}
        {...tippyProps}
      >
        {children}
      </Tippy>
    )}
  </>
));

export default Tooltip;
