import styled from 'styled-components';

// Types
import type { ReactNode } from 'react';
import type { Toast as ToastType, ToastLevel } from './types';

// Styles & Icons
import CloseIcon from '@/assets/icons/content/close.svg';
import SuccessIcon from '@/assets/icons/content/success.svg';
import WarningIcon from '@/assets/icons/content/warning.svg';
import ErrorIcon from '@/assets/icons/content/error.svg';
import Linkv2 from '../link/Linkv2';

type ToastProps = ToastType;

const levelLabel: Record<ToastLevel, string> = {
  success: 'Success',
  warning: 'Warning',
  error: 'Error',
};

const levelIcons: Record<ToastLevel, ReactNode> = {
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
};

const ToastStyled = styled.button`
  cursor: auto;
  background: var(--grid-column-bg);
  color: var(--content-font);
  border-radius: var(--border-radius);
  box-shadow: var(--quo-char-50) 0px 1px 4px -1px;
  width: 100%;
  min-height: 50px;
  text-align: left;
  padding: 5px;
  display: flex;
  align-items: stretch;
  margin-top: 15px;
  pointer-events: all;
  will-change: transform, opacity;
  animation: enter 0.2s forwards;
  position: relative;

  &.success {
    --toast-color: var(--message-success);
  }

  &.warning {
    --toast-color: var(--message-warning);
  }

  &.error {
    --toast-color: var(--message-error);
  }

  @keyframes enter {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      top: translateX(0);
    }
  }
`;

const ToastLabelStyled = styled.span`
  font-weight: bold;
  color: var(--toast-color);
  display: block;
`;

const ToastMessage = styled.span`
  margin-top: 5px;
  display: block;
`;

const ToastLink = styled.a`
  display: block;
  margin-top: 5px;
  word-break: break-all;
`;

const ToastIconStyled = styled.div`
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 100%;
    background-color: var(--toast-color);
    margin-right: 5px;
  }
`;

const OutputStyled = styled.output`
  padding: 10px;
  flex-grow: 1;
`;

const CloseIconStyled = styled(CloseIcon)`
  align-self: center;
  cursor: pointer;
  flex-shrink:0;
`;

export const Toast = ({ message, clear, level, link }: ToastProps) => (
  <ToastStyled onClick={clear} className={level}>
    <ToastIconStyled>
      {levelIcons[level]}
    </ToastIconStyled>
    <OutputStyled>
      <ToastLabelStyled>{levelLabel[level]}</ToastLabelStyled>

      <ToastMessage>
        {message}
        {link && <Linkv2 as={ToastLink} href={link} target="_blank">{link}</Linkv2>}
      </ToastMessage>
    </OutputStyled>
    <CloseIconStyled />
  </ToastStyled>
);
