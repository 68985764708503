import { useEffect } from 'react';
import styled from 'styled-components';

// Components
import { RichText } from '../markdown/Markdown';

// Types
import type { MessageConfig, MessageType } from '../../../hooks/useMessage';

// Misc
import { sanitize } from '@/utils/sanitize';
import RouterLink from '../link/RouterLink';

interface MessageProps extends MessageConfig {
  message?: MessageType | null;
  removeMessage?: VoidFunction;
}

const MessageWrapper = styled.div<{ $type: string; $height: string; }>`
  background: var(--${(props) => props.$type}-message-bg);
  padding: 15px;
  border-radius: 4px;
  height: ${(props) => props.$height};

  &:not(:first-child) {
    margin-top: 25px;
  }
`;

const MessageTextWrapper = styled.div<{ $type: string; $align: string; $size: number; $height: string; }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.$align};
  height: ${(props) => props.$height};

  p {
    color: var(--${(props) => props.$type}-message-font);
    font-size: ${(props) => props.$size}px;
  }

  button {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }
`;

const Message = ({ message, removeMessage, closeButton, customHtml, timeout, link }: MessageProps) => {
  useEffect(() => {
    let messageTimeout: ReturnType<typeof setTimeout>;

    if (message && timeout) {
      messageTimeout = setTimeout(() => {
        if (removeMessage) removeMessage();
      }, timeout);
    }

    return () => clearTimeout(messageTimeout);
  }, [message]);

  if (!message) {
    return null;
  }

  return (
    <MessageWrapper
      $height={message.height || 'auto'}
      $type={message.type}
    >
      <MessageTextWrapper
        $height={message.height || 'auto'}
        $type={message.type}
        $size={message.size || 16}
        $align={message.align || 'space-between'}
      >
        {customHtml ? (
          <div>
            <RichText dangerouslySetInnerHTML={{ __html: sanitize(message.text) }} />
          </div>
        ) : (
          <p>{message.text}</p>
        )}
        {closeButton && <button type="button" onClick={removeMessage} aria-label="Close Message">×</button>}
        {link && <RouterLink to={link.to}>{link.text}</RouterLink>}
      </MessageTextWrapper>
    </MessageWrapper>
  );
};

export default Message;
