import type { FilterValues } from '../../types';
import type { Multiple } from '@/types/meta';

export const getSelected = ({
  filter,
  filterMeta,
}: FilterValues<Multiple, any>) => {
  if (!filter) {
    return [];
  }

  if (typeof filter === 'number' || typeof filter === 'string') {
    const choice = filterMeta.choices.find((item) => String(item.value) === filter);
    return choice ? [{ value: choice.value, label: choice.label }] : [];
  }

  return filterMeta.choices
    .filter((item) => filter.indexOf(String(item.value)) !== -1)
    .map((choice) => ({ value: choice.value, label: choice.label }));
};
