import { ComboboxRemoveSelected, ComboboxSelected, ComboboxSelectedItem } from './multiselect.styles';
import { useSelect } from './SelectContext';

const SelectedItems = () => {
  const { selectedItems, removeSelectedItem } = useSelect();

  return (
    <>
      {selectedItems.length > 0 && (
        <ComboboxSelected>
          {selectedItems.map((item) => (
            <ComboboxSelectedItem key={`selected-${item.value}`}>
              {item.label}
              <ComboboxRemoveSelected type="button" onClick={() => removeSelectedItem(item)}>x</ComboboxRemoveSelected>
            </ComboboxSelectedItem>
          ))}
        </ComboboxSelected>
      )}
    </>
  );
};

export default SelectedItems;
