import { useEffect, type PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Loading from '@/components/content/loading/Loading';

// // Hooks
import { useAdminRedirect } from '../hooks/useAdminRedirection';
import { useSession } from '@/hooks/useSession';
import { useAuth } from '@/hooks/useAuth';

// Misc
import { isServiceUser } from '@/utils/usersRole';
import { isInAdminDomain } from '@/utils/admin';

const Auth = ({ children }: PropsWithChildren<{}>) => {
  const location = useLocation();
  const { hasAdminRedirect, returnToMercuryOrigin, redirectAdmin } = useAdminRedirect();
  const { userInfo, isSuccess, isLoading, isError } = useAuth();
  const { startSession } = useSession();

  useEffect(() => {
    if (isSuccess) {
      startSession();
    }
  }, [isSuccess]);

  if (isLoading) {
    return <Loading text="Verifying" height="100vh" />;
  }

  if (isError) {
    if (isInAdminDomain()) {
      returnToMercuryOrigin('login');
      return null;
    }

    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (isServiceUser(userInfo.role)) {
    return (
      <Navigate
        to="/login"
        state={{
          display_modal: true,
          modal_title: 'Sorry',
          modal_text: `
          Your account doesn't have access to Mercury.
          Please try to login with a valid account or
          contact your dedicated Customer Success Manager if you need assistance.
        `,
        }}
      />
    );
  }

  if (userInfo.two_factor_force_admin && !userInfo.two_factor_enabled) {
    returnToMercuryOrigin(`activate2fa?username=${encodeURIComponent(userInfo.email)}`);
    return null;
  }

  if (hasAdminRedirect(userInfo)) {
    redirectAdmin();
    return null;
  }

  return <>{children}</>;
};

export default Auth;
