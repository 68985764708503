import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PropsWithChildren, useState } from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { QueryClientProvider, UseQueryOptions } from '@tanstack/react-query';
import { MantineProvider, type CSSVariablesResolver, createTheme, localStorageColorSchemeManager } from '@mantine/core';

// Context
import { MercuryPagesProvider } from './context/MercuryPagesContext';
import { MercuryPermissionsProvider } from './context/MercuryPermissions';
import { ToastContextProvider } from './context/ToastContext';
import { ToastContainer } from './components/content/toast/ToastContainer';
import { ConfirmationProvider } from './components/content/confirmationdialog/ConfirmationContext';
import { SessionContextProvider } from './context/SessionContext';
import { ThemeProvider as MercuryThemeProvider } from './context/ThemeContext';
import { DrawerProvider } from './context/DrawerContext';
import Priority from './components/Priority';

import { queryClient as qc } from './lib/react-query/client';
import { PermanentMessageProvider } from './context/PermanentMessageContext';
import { PermanentMessage } from './components/content/permanentmessage/PermanentMessage';
import { useScrollToAnchor } from './hooks/useScrollToAnchor';

import type { UserId } from './types/api';

import './mantinecomponents';

export const theme = {
  breakpoints: {
    XS: '576px',
    S: '768px',
    SMax: '767px',
    M: '992px',
    MMax: '991px',
    L: '1280px',
    LMax: '1279px',
  },
};

const mantineTheme = createTheme({
  breakpoints: {
    xs: '36em',
    s: '48em',
    sm: '47.9375em',
    m: '62em',
    mm: '61.9375em',
    lg: '80em',
    lm: '79.9375em',
    xl: '90em',
  },
});

const resolver: CSSVariablesResolver = () => ({
  variables: {},
  light: {
    '--mantine-color-body': 'var(--quo-white)',
  },
  dark: {
    '--mantine-color-body': 'var(--quo-char)',
  },
});

const colorSchemeManager = localStorageColorSchemeManager({
  key: 'theme',
});

const App = ({
  children,
  prefetchQueries = [],
}: PropsWithChildren<{ prefetchQueries?: Array<(userId: UserId) => Omit<UseQueryOptions<any>, 'select'>> }>) => {
  const [queryClient] = useState(qc());

  useScrollToAnchor();

  return (
    <StyleSheetManager>
      <MantineProvider
        theme={mantineTheme}
        cssVariablesResolver={resolver}
        colorSchemeManager={colorSchemeManager}
        defaultColorScheme="auto"
      >
        <ThemeProvider theme={theme}>
          <MercuryThemeProvider>
            <QueryClientProvider client={queryClient}>
              <MercuryPagesProvider>
                <MercuryPermissionsProvider>
                  <ConfirmationProvider>
                    <ToastContextProvider>
                      <DrawerProvider>
                        <PermanentMessageProvider>
                          <SessionContextProvider>
                            {children}
                            <ToastContainer />
                            <PermanentMessage />
                            <ReactQueryDevtools initialIsOpen={false} />
                            <Priority queries={prefetchQueries} />
                          </SessionContextProvider>
                        </PermanentMessageProvider>
                      </DrawerProvider>
                    </ToastContextProvider>
                  </ConfirmationProvider>
                </MercuryPermissionsProvider>
              </MercuryPagesProvider>
            </QueryClientProvider>
          </MercuryThemeProvider>
        </ThemeProvider>
      </MantineProvider>
    </StyleSheetManager>
  );
};

export default App;
