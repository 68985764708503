import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { usePermanentMessage } from '@/hooks/usePermanentMessage';
import { useMemo } from 'react';

const PermanentMessageStyled = styled.div`
  position: fixed;
  height: 35px;
  top: 0;
  width: 100vw;
  z-index: 99999;
  pointer-events: none;
  padding: 10px;
  background-color: var(--message-color);
  transition: 0.3s ease-in-out background-color;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  &.success {
    --message-color: var(--message-success);
  }

  &.warning {
    --message-color: var(--message-warning);
  }

  &.error {
    --message-color: var(--message-error);
  }
`;

export const PermanentMessage = () => {
  const permanentMessageRef = useMemo(() => document.querySelector('#permanentmessage')! as HTMLElement, []);

  const { message } = usePermanentMessage();

  if (!message) {
    return null;
  }

  return ReactDOM.createPortal(
    <PermanentMessageStyled className={message.level}>
      <span>{message?.message}</span>
    </PermanentMessageStyled>,
    permanentMessageRef,
  );
};
