import { PropsWithChildren, useMemo } from 'react';
import { useMultipleSelection, useCombobox } from 'downshift';

import { SelectContext } from './SelectContext';
import { useItemsStore } from './useItemsStore';

import type { DownshiftProps, ItemsStoreProps } from './types';

interface MultiSelectProviderProps extends Omit<DownshiftProps, 'downshiftItems'>, ItemsStoreProps {
  name: string;
}

export const MultiSelectProvider = ({
  children,
  items,
  name,
  selectedItems,
  onComboboxStateChange,
  onMultipleStateChange,
}: PropsWithChildren<MultiSelectProviderProps>) => {
  const itemsStore = useItemsStore({ items, selectedItems });

  const { setInputValue, inputValue, listStore, isSelected, downshiftItems, isDisabled } = itemsStore;

  const multiple = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          if (newSelectedItems) {
            onMultipleStateChange(newSelectedItems);
            setInputValue('');
          }
          break;
        default:
          break;
      }
    },
  });

  const combobox = useCombobox({
    items: downshiftItems,
    itemToString(item) {
      return item ? item.label : '';
    },
    isItemDisabled: (item) => isDisabled(item.value),
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    onStateChange({ inputValue: newInputValue, type, selectedItem: newSelectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (newSelectedItem) {
            const selected = isSelected(newSelectedItem.value);

            if (selected) {
              onComboboxStateChange(
                selectedItems.filter((selectedItem) => selectedItem.value !== newSelectedItem.value),
              );
              return;
            }

            const item = listStore.byValue[newSelectedItem.value];
            let actualSelectedValue = [...selectedItems, newSelectedItem]; // default is to add the new item

            // if its a parent, select the main item and remove all the children
            if (item.choices && item.choices.length > 0) {
              const allChildren = item.choices.flatMap(({ ...rest }) => [
                rest.value,
                ...(rest.choices ? rest.choices : []).map((o) => o.value),
              ]);
              const nonSelectedChildren = selectedItems.filter(
                (selectedChildren) => !allChildren.includes(selectedChildren.value),
              );

              actualSelectedValue = [...nonSelectedChildren, newSelectedItem];
            }

            onComboboxStateChange(actualSelectedValue);
            setInputValue('');
          }

          break;
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue as string);
          break;
        default:
          break;
      }
    },
  });

  const value = useMemo(
    () => ({
      combobox,
      selectedItems: multiple.selectedItems,
      removeSelectedItem: multiple.removeSelectedItem,
      dropdownProps: multiple.getDropdownProps({ name }),
      itemsStore,
    }),
    [selectedItems.length, combobox],
  );

  return <SelectContext.Provider value={value}>{children}</SelectContext.Provider>;
};
