/* eslint-disable max-len */
import styled from 'styled-components';

const LoaderWrapper = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height};

  p {
    font-size: 24px;
    margin-top: 25px;
  }
`;

export const Loader = styled.div<{
  $background: string;
  $loaderWidth: number;
  $loaderHeight: number;
  $pseudoElementWidth: number;
  $pseudoElementHeight: number;
  $unit: string;
}>`
  border-radius: 50%;
  color: var(--active-color);
  font-size: 11px;
  text-indent: -99999em;
  position: relative;
  width: ${(props) => `${props.$loaderWidth}${props.$unit}`};
  height: ${(props) => `${props.$loaderHeight}${props.$unit}`};
  box-shadow: inset 0 0 0 1px;
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &:before,
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }

  &:before {
    width: ${(props) => `${props.$pseudoElementWidth}${props.$unit}`};
    height: ${(props) => `${props.$pseudoElementHeight}${props.$unit}`};
    background: ${(props) => props.$background};
    border-radius: ${(props) => `${props.$pseudoElementWidth}${props.$unit}`} 0 0
      ${(props) => `${props.$pseudoElementHeight}${props.$unit}`};
    top: -0.1em;
    left: -0.1em;
    transform-origin: ${(props) => `${props.$pseudoElementWidth - 0.1}${props.$unit}`} ${(props) => `${props.$pseudoElementWidth - 0.1}${props.$unit}`};
    animation: load 2s infinite ease 1.5s;
  }

  &:after {
    width: ${(props) => `${props.$pseudoElementWidth}${props.$unit}`};
    height: ${(props) => `${props.$pseudoElementHeight}${props.$unit}`};
    background: ${(props) => props.$background};
    border-radius: 0 ${(props) => `${props.$pseudoElementHeight}${props.$unit}`}
      ${(props) => `${props.$pseudoElementHeight}${props.$unit}`} 0;
    top: -0.1em;
    left: ${(props) => `${props.$pseudoElementWidth - 0.3}${props.$unit}`};
    transform-origin: 0.1em ${(props) => `${props.$pseudoElementWidth - 0.1}${props.$unit}`};
    animation: load 2s infinite ease;
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = ({
  text,
  height = '100%',
  background = 'var(--mercury-bg)',
  loaderWidth = 10,
  loaderHeight = 10,
  unit = 'em',
}: {
  text?: string;
  height?: string;
  background?: string;
  loaderWidth?: number;
  loaderHeight?: number;
  unit?: string;
}) => {
  const pseudoElementWidth = loaderHeight / 2;

  return (
    <LoaderWrapper height={height}>
      <Loader
        $background={background}
        $loaderWidth={loaderWidth}
        $loaderHeight={loaderHeight}
        $pseudoElementWidth={pseudoElementWidth + 0.2}
        $pseudoElementHeight={loaderHeight + 0.2}
        $unit={unit}
      />
      {text && <p>{text}</p>}
    </LoaderWrapper>
  );
};

export default Loading;
