import { useMemo } from 'react';

import Combobox from '@/components/content/form/components/combobox/Combobox';
import Menu from '@/components/content/form/components/combobox/Menu';
import SelectedItems from '@/components/content/form/components/combobox/SelectedItems';

import type { FilterProps } from '../../types';
import type { Multiple } from '@/types/meta';

import { getSelected } from './utils';
import { arrayToTree } from '@/utils/arrayToTree';
import { MultiSelectProvider } from '@/components/content/form/components/combobox/MultiSelectPovider';

const ComboboxFilter = ({ filterMeta, filter, setFilter }: FilterProps<Multiple, number[] | number | string>) => {
  const selectedItems = useMemo(() => getSelected({ filter, filterMeta }), [filter]);

  const parentId = filterMeta.choices.some((choice) => choice.parent_cat) ? 'parent_cat' : 'parent_id';

  return (
    <MultiSelectProvider
      name={filterMeta.name}
      items={arrayToTree(filterMeta.choices, { id: 'value', parentId })}
      selectedItems={selectedItems}
      onComboboxStateChange={(newSelectedItem) => {
        if (newSelectedItem) {
          setFilter({ [filterMeta.name]: newSelectedItem.map((item) => item.value) });
        }
      }}
      onMultipleStateChange={(newSelectedItems) => {
        const value = newSelectedItems?.length ? newSelectedItems.map((item) => item.value) : undefined;
        setFilter({ [filterMeta.name]: value });
      }}
    >
      <Combobox placeholder={filterMeta.label}>
        <Menu />
        <SelectedItems />
      </Combobox>
    </MultiSelectProvider>
  );
};

export default ComboboxFilter;
