import { Group } from '@mantine/core';

import MetaContainer from './MetaContainer';
import MetaSection from './MetaSection';
import Search from './Search';
import Button from '@/components/content/button/Button';
import MetaPlaceholder from './MetaPlaceholder';

import type { FiltersMeta } from '@/types/meta';
import type { MetaCategories } from '../types';
import type { MetaStore } from '@/hooks/useMetaStore';

import classes from './Meta.module.css';

interface MetaWrapperProps {
  metaData?: { meta: MetaCategories | FiltersMeta; defaultValues?: Record<string, any> | null; }
  metaStore: MetaStore;
  hideSearch?: boolean;
  hideClear?: boolean;
}

const MetaWrapper = ({
  metaData,
  metaStore,
  hideSearch,
  hideClear,
}: MetaWrapperProps) => {
  if (!metaData) {
    return <MetaPlaceholder />;
  }

  const renderMeta = metaData.meta;
  const isRawMeta = Array.isArray(renderMeta);

  return (
    <div>
      {!hideSearch && !hideClear && (
        <Group>
          {!hideSearch && <Search setSearch={metaStore.search.set} search={metaStore.search.state.search} />}

          {isRawMeta && !hideClear && (
            <Button onClick={metaStore.clearAll} background="var(--quo-power)" color="var(--quo-white)">
              Clear Filters
            </Button>
          )}
        </Group>
      )}

      <div className={classes.metaRoot}>
        {isRawMeta ? (
          <MetaContainer meta={renderMeta} store={metaStore} />
        ) : (
          <MetaSection
            metaCategories={renderMeta}
            store={metaStore}
          />
        )}
      </div>
    </div>
  );
};

export default MetaWrapper;
