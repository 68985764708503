import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToAnchor = () => {
  const scrolled = useRef(false);
  const offsetTop = useRef(-1);
  const { hash: hashRaw } = useLocation();
  const hash = hashRaw.split('#')[1];

  const offset = 60;

  const getElement = (unscapedHash: string): HTMLElement | null => (
    document.querySelector(`#${CSS.escape(unscapedHash)}`)
  );

  useEffect(() => {
    if (hash) {
      const checkTargetAndScroll = () => {
        const element = getElement(hash);

        if (element) {
          const position = element.getBoundingClientRect();
          window.scrollTo(0, position.y - offset + window.scrollY);
          offsetTop.current = element.offsetTop;
          scrolled.current = true;
        }
      };

      checkTargetAndScroll();

      const mutationObserver = new MutationObserver(() => {
        if (!scrolled.current) {
          checkTargetAndScroll();
        } else {
          const element = getElement(hash);
          const difference = offsetTop.current - (element?.offsetTop ?? 0);
          window.scrollTo(0, window.scrollY - difference);
          offsetTop.current = element?.offsetTop ?? 0;
        }
      });

      mutationObserver.observe(document.body, {
        subtree: true,
        childList: true,
      });

      return () => {
        mutationObserver.disconnect();
      };
    }

    window.scrollTo(0, 0);

    return () => {};
  }, [hash]);
};
