import { Box } from '@mantine/core';

import FlexibleGridBox from '@/components/content/box/FlexibleGridBox';
import Skeleton from '@/components/content/placeholder/Skeleton';

const MetaPlaceholder = () => {
  const height = 35;

  return (
    <>
      <Skeleton height={height} radius="4px" />

      <Box mt={25}>
        <FlexibleGridBox count={{ S: 2, M: 4, L: 3 }}>
          <Skeleton n={6} id="meta" height={height} radius="4px" />
        </FlexibleGridBox>
      </Box>
    </>
  );
};

export default MetaPlaceholder;
