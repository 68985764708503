import styled, { css } from 'styled-components';

const ComboboxMenuBase = styled.ul`
  position: sticky;
  width: 100%;
  max-height: 350px;
  background-color: var(--grid-column-bg);
  overflow-y: auto;
  z-index: 1;
  box-shadow: var(--quo-char-50) 0px 1px 4px -1px;
  border-radius: 4px;
  z-index: 1;
`;

const hidden = css`display: none;`;

export const ComboboxMenuContainer = styled.div`
  position: absolute;
  width: 100%;
`;

export const ComboboxMenu = styled(ComboboxMenuBase) <{ hidden: boolean }>`
  ${(props) => props.hidden && hidden}
`;

export const ComboboxSelected = styled.ul <{ single?: boolean }>`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 15px;
  width: ${(props) => (props.single ? '100%' : 'auto')};
`;

export const ComboboxSelectedItem = styled.li`
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid var(--default-border);
  border-radius: var(--border-radius);
  font-size: 13px;
  padding: 5px;
`;

export const ComboboxRemoveSelected = styled.button`
  color: var(--quo-power);
`;
