import { useRef } from 'react';
import { ActionIcon, Group, Popover, Stack } from '@mantine/core';
import { AriaDateRangePickerProps, DateValue, useDateRangePicker } from 'react-aria';
import { useDateRangePickerState } from 'react-stately';

import RangeCalendar from './RangeCalendar';
import DateField from './DateField';

import classes from './DatePicker.module.css';
import CalendarIcon from '@/assets/icons/content/calendar.svg';
import RightArrow from '@/assets/icons/content/right-arrow.svg';
import WarningIcon from '@/assets/icons/content/warning.svg';

import type { Presets, RangeValue } from './types';

interface DatePickerProps extends AriaDateRangePickerProps<DateValue> {
  presets?: Presets;
  onChange: ((value: RangeValue) => void);
  value: RangeValue | null;
}

const DatePicker = ({ presets, onChange, value, ...datePickerProps }: DatePickerProps) => {
  const nullOrVal = typeof value === 'undefined' ? null : value;

  const ref = useRef(null);
  const state = useDateRangePickerState({ onChange, value: nullOrVal });

  const {
    startFieldProps,
    endFieldProps,
    calendarProps,
    errorMessageProps,
  } = useDateRangePicker({ onChange, value: nullOrVal, ...datePickerProps }, state, ref);

  return (
    <Popover
      classNames={{ dropdown: classes.datePickerDropdown }}
      opened={state.isOpen}
      onChange={state.setOpen}
      position="bottom-start"
    >
      <Popover.Target>
        <Stack gap={5}>
          <Group
            align="center"
            wrap="nowrap"
            classNames={{ root: `${classes.datePickerWrapper} ${state.isInvalid ? classes.error : ''}` }}
          >
            <Group gap={5}>
              <DateField {...startFieldProps} />
              <RightArrow />
              <DateField {...endFieldProps} />
            </Group>
            <ActionIcon variant="transparent" onClick={() => state.setOpen(!state.isOpen)}>
              <CalendarIcon />
            </ActionIcon>
          </Group>

          {state.isInvalid && (
            <span {...errorMessageProps} className={classes.datePickerError}>
              <WarningIcon />
              Start date must be before end date.
            </span>
          )}
        </Stack>
      </Popover.Target>

      <Popover.Dropdown>
        <RangeCalendar rangeCalendarProps={calendarProps} presets={presets} />
      </Popover.Dropdown>
    </Popover>
  );
};

export default DatePicker;
