import { useMantineColorScheme } from '@mantine/core';
import { PropsWithChildren, createContext, useMemo, useState } from 'react';

const getInitialTheme = () => {
  const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
  const currentTheme = localStorage.getItem('theme');

  return currentTheme || (prefersDarkScheme.matches ? 'dark' : 'light');
};

interface ThemeContextProps {
  theme: string;
  changeTheme: (theme: string) => void;
}

export const ThemeContext = createContext<ThemeContextProps | null>(null);

export const ThemeProvider = ({ children }: PropsWithChildren<{}>) => {
  const [theme, setTheme] = useState(getInitialTheme());
  const { setColorScheme } = useMantineColorScheme();

  const changeTheme = (newTheme: string) => {
    document.documentElement.dataset.theme = newTheme;
    setColorScheme(newTheme as any);
    localStorage.setItem('theme', newTheme);

    setTheme(newTheme);
  };

  const value = useMemo(() => ({ theme, changeTheme }), [theme]);

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};
