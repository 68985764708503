import { type ReactNode, useCallback } from 'react';
import { UnstyledButton } from '@mantine/core';

import Tooltip from '@/components/content/tooltip/Tooltip';
import ConditionalWrapper from '@/components/content/conditionalwrapper/ConditionalWrapper';

import classes from './DatePicker.module.css';

import type { RangeValue, Preset } from './types';

interface PresetProps {
  setNewRange: (value: RangeValue) => void;
  preset: Preset;
}

const PresetComp = ({ preset, setNewRange }: PresetProps) => {
  const wrapperTooltip = useCallback(
    (children: ReactNode) => <Tooltip title={preset.preview as string}>{children}</Tooltip>,
    [],
  );

  return (
    <ConditionalWrapper condition={typeof preset.preview !== 'undefined'} wrapper={wrapperTooltip}>
      <UnstyledButton onClick={() => setNewRange(preset.value)} classNames={{ root: classes.datePickerPreset }}>
        {preset.label}
      </UnstyledButton>
    </ConditionalWrapper>
  );
};

export default PresetComp;
