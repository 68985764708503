import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { Toast } from './Toast';
import { useToast } from '@/hooks/useToast';
import { useMemo } from 'react';

const ToastContainerStyled = styled.div`
  position: fixed;
  height: auto;
  width: 300px;
  max-width: calc(100vw - 30px);
  z-index: 99999;
  right: 15px;
  pointer-events: none;
  bottom: 15px;

  @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    top: 80px;
    bottom: auto;
  }
`;

export const ToastContainer = () => {
  const toastsContainerRef = useMemo(() => document.querySelector('#toasts')! as HTMLElement, []);

  const { toasts } = useToast();

  return ReactDOM.createPortal(
    <ToastContainerStyled>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </ToastContainerStyled>,
    toastsContainerRef,
  );
};
