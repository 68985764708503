import { TextInput, TextInputProps } from '@mantine/core';

import IconButton from '@/components/content/button/IconButton';
import SearchIcon from '@/assets/icons/content/search.svg';

import classes from './SearchBar.module.css';

interface SearchBarProps extends TextInputProps {
  onSearch?: VoidFunction;
  search?: string;
}

const SearchBar = ({
  onSearch,
  search,
  ...inputProps
}: SearchBarProps) => (
  <TextInput
    classNames={{ root: classes.root, input: classes.input }}
    value={search}
    role="searchbox"
    rightSection={(
      <IconButton
        onClick={() => onSearch?.()}
        icon={<SearchIcon />}
        label="Search"
      />
    )}
    {...inputProps}
  />
);

export default SearchBar;
