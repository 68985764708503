import { type ChangeEvent, useMemo, useState, useEffect } from 'react';

// Misc
import { debounce } from '@/utils/debounce';

import classes from './Meta.module.css';
import SearchBar from '@/components/content/form/components/searchbar/SearchBar';
import { UrlStateActions } from '@/hooks/useUrlStore';

interface SearchProps {
  debounceTime?: number;
  setSearch: UrlStateActions['set'];
  search: string;
}

const Search = ({ setSearch, search, debounceTime = 500 }: SearchProps) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (search !== value) {
      setValue(search);
    }
  }, [setValue, search]);

  const eventHandler = (e: ChangeEvent<HTMLInputElement>) => setSearch({ search: e.target.value });
  const setValueCb = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
  const onChange = useMemo(() => debounce(eventHandler, setValueCb, debounceTime), []);

  return (
    <SearchBar
      search={value ?? ''}
      onChange={onChange}
      placeholder="Search"
      classNames={{
        wrapper: classes.search,
        root: classes.search,
        input: classes.searchInput,
      }}
    />
  );
};
export default Search;
