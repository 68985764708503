import type { ContextVariables, FetchDataArguments } from '../types/api';
import { SERVER_ERROR } from '@/constants/text/english';

export const HOST = '/api';

class FetchError extends Error {
  constructor({ error: { message, code, details } }: any = {}, errorMessage?: string | boolean) {
    super(errorMessage || message);
    Object.assign(this, { code, details });
  }
}

const getBuiltError = (code: number, message: string) => ({ error: { code, message } });

const addContext = (endpoint: string, context?: ContextVariables) => {
  if (!context) return endpoint;

  const [url, queryParams] = endpoint.split('?');

  const contextParams = Object.entries(context).reduce((accu, [key, val]) => {
    accu.append(`ctx_${key}`, val);

    return accu;
  }, new URLSearchParams()).toString();

  return `${url}${queryParams ? `?${queryParams}&${contextParams}` : `?${contextParams}`}`;
};

export const fetchData = async ({
  endpoint,
  options = { headers: {} },
  error = SERVER_ERROR,
  noContent = 'No data available',
  unauthorized = SERVER_ERROR,
  requestLimit = SERVER_ERROR,
  useResponseError = false,
  resolveOnNoContent = false,
  context,
}: FetchDataArguments, signal?: AbortSignal) => {
  const endpointWithContext = addContext(endpoint, context);
  const response = await fetch(`${HOST}/${endpointWithContext}`, { credentials: 'include', signal, ...options });
  let json;

  if (response.status >= 500) {
    throw new FetchError(getBuiltError(
      response.status,
      SERVER_ERROR,
    ));
  }

  if (response.status === 401) {
    throw new FetchError(getBuiltError(
      response.status,
      unauthorized,
    ));
  }

  if (response.status === 429) {
    throw new FetchError(getBuiltError(
      response.status,
      requestLimit,
    ));
  }

  if (!response.ok) {
    const text = await response.text();

    if (!text) {
      throw new FetchError(getBuiltError(
        response.status,
        error,
      ));
    }

    // Throw a fetch error with an optional custom error.
    throw new FetchError(JSON.parse(text), useResponseError ? false : error);
  }

  if (response.status === 204) {
    if (resolveOnNoContent) {
      return response;
    }

    throw new FetchError(getBuiltError(response.status, noContent));
  }

  try {
    json = await response.json();
    // eslint-disable-next-line no-empty
  } catch (e) { }

  if (json) {
    return json;
  }

  return response;
};
