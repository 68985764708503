import { MutationCache, QueryCache, QueryClient, QueryObserverOptions } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const queryDefaultOptions: QueryObserverOptions = {
  retry: 0,
  refetchOnWindowFocus: false,
  refetchOnReconnect: 'always',
  staleTime: 60 * 5000, // 5 minutes,
  networkMode: 'always',
};

export const queryClient = () => {
  const navigate = useNavigate();

  const handleUnauthorized = (avoidAuthRedirection: unknown, errorCode: number) => {
    if (avoidAuthRedirection) {
      return;
    }

    if (errorCode === 401) {
      navigate('/login', {
        state: {
          display_modal: true,
          modal_title: 'Your session expired',
          modal_text: 'Your session expired due to inactivity. Please login again to keep using mercury features.',
        },
      });
    }
  };

  return () => new QueryClient({
    mutationCache: new MutationCache({
      onError: (error: any, variables, context, mutation) => {
        handleUnauthorized(mutation.meta?.avoidAuthRedirection, error.code);
      },
    }),
    queryCache: new QueryCache({
      onError: (error: any, query) => {
        handleUnauthorized(query.meta?.avoidAuthRedirection, error.code);
      },
    }),
    defaultOptions: {
      queries: queryDefaultOptions,
    },
  });
};
