type TTree<T> = {
  choices: TTree<T>[];
  depth: number;
  name: string;
  id: number;
  value: number;
  parent_id: number;
} & T;

type ListReturn<T> = TTree<T>[];

export type IListArguments = {
  id: string;
  parentId: string;
};

export const arrayToTree = <T>(
  list: T[],
  { id, parentId }: IListArguments,
): ListReturn<T> => {
  // map between id and array position
  const map: number[] = [];
  const treeList: TTree<T>[] = list as TTree<T>[];

  for (let i = 0; i < treeList.length; i += 1) {
    const oldValue = map[(treeList[i] as TTree<T> & { [id: string]: number })[id]];
    if (!oldValue || !(treeList[i] as TTree<T> & { [id: string]: number })[parentId]) {
      // initialize the map
      map[(treeList[i] as TTree<T> & { [id: string]: number })[id]] = i;
    }
    // initialize the children
    treeList[i].choices = [];
  }

  let node: TTree<T> & { [parentId: string]: number | string };
  // return value
  const roots: TTree<T>[] = [];

  treeList.forEach((item) => {
    node = item as TTree<T> & { [parentId: string]: number | string };
    if (node[parentId]) {
      const parent: any = treeList[map[node[parentId] as number]];

      if (parent !== undefined) {
        const depth = !parent[parentId] ? 1 : 2;
        parent.choices?.push({ ...node, depth });
      }
    } else {
      roots.push({ ...node, depth: 0 });
    }
  });

  return roots;
};
