import { Link } from 'react-router-dom';

// Components
import Logout from '@/features/auth/components/Logout';
import DropdownMenu from './DropdownMenu';

import { isAdmin } from '@/utils/usersRole';
import { useAuth } from '@/hooks/useAuth';

// Styles & Assets
import UserIcon from '@/assets/icons/user.svg';
import classes from './Topbar.module.css';

const ProfileMenu = () => {
  const { userInfo: user } = useAuth();
  const displaySubscriptiosn = !isAdmin(user.role);

  return (
    <DropdownMenu icon={UserIcon}>
      <li className={classes.menuItem}>
        <Link to="account">My Account</Link>
      </li>
      {displaySubscriptiosn && (
        <li className={classes.menuItem}>
          <Link to="subscriptions">My Subscriptions</Link>
        </li>
      )}
      <li className={classes.menuItem}>
        <Logout />
      </li>
    </DropdownMenu>
  );
};

export default ProfileMenu;
