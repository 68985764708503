import type { UserInfo } from '../types';

import { adminHost, isInAdminDomain } from '@/utils/admin';
import { isAdmin } from '@/utils/usersRole';

export const useAdminRedirect = () => {
  const hasAdminRedirect = (user: UserInfo) => isAdmin(user.role) && !isInAdminDomain();

  const redirectAdmin = () => {
    window.location.replace(`${window.location.protocol}//${adminHost()}/admin`);
  };

  const returnToMercuryOrigin = (path: string) => {
    window.location.replace(`${window.location.protocol}//${adminHost()?.replace('-admin', '')}/${path}`);
  };

  return {
    hasAdminRedirect,
    redirectAdmin,
    returnToMercuryOrigin,
  };
};
