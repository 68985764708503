import MetaComponent from './MetaComponent';
import FlexibleGridBox from '@/components/content/box/FlexibleGridBox';

import type { FiltersMeta } from '@/types/meta';
import type { MetaStore } from '@/hooks/useMetaStore';

import classes from './Meta.module.css';

interface MetaContainerProps {
  meta: FiltersMeta;
  store: MetaStore;
}

const MetaContainer = ({ meta, store }: MetaContainerProps) => (
  <div className={classes.container}>
    <FlexibleGridBox count={{ S: 2, M: 4, L: 5 }}>
      {meta.map((m) => (
        <div key={m.name} className={meta.length === 1 ? classes.metaMaxWidth : ''}>
          <MetaComponent meta={m} store={store} />
        </div>
      ))}
    </FlexibleGridBox>
  </div>
);

export default MetaContainer;
