import { useClickOutside, useDisclosure } from '@mantine/hooks';
import { Transition } from '@mantine/core';

import IconButton from '@/components/content/button/IconButton';
import SearchBar from './NavBarSearchBar';

import classes from './NavBarSearchBar.module.css';
import SearchIcon from '@/assets/icons/content/search.svg';

const Mobile = () => {
  const [opened, { open, close }] = useDisclosure();
  const clickOutsideRef = useClickOutside(close);

  return (
    <div>
      <IconButton
        icon={<SearchIcon />}
        label="Open Search"
        onClick={open}
      />

      <Transition
        mounted={opened}
        transition="slide-down"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <div style={styles} className={classes.mobileWrapper} ref={clickOutsideRef}>
            <SearchBar onSearch={close} />
          </div>
        )}
      </Transition>
    </div>
  );
};

export default Mobile;
