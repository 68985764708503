import { Fragment, ReactNode, useCallback } from 'react';
import { Skeleton, type SkeletonProps } from '@mantine/core';

import classes from './Skeleton.module.css';

interface MercurySkeletonProps extends SkeletonProps {
  n?: number;
  id?: string;
  renderItem?: (item: ReactNode) => ReactNode;
}

const MercurySkeleton = ({ n, renderItem, id, ...skeletonProps }: MercurySkeletonProps) => {
  const SkeletonComp = useCallback(
    () => (
      <Skeleton classNames={{ root: classes.skeleton }} {...skeletonProps} />),
    [],
  );

  if (n === 1) {
    SkeletonComp();
  }

  const placeholders = [...Array(n || 1)];

  return (
    <>
      {placeholders.map((el, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`${id ?? 'skeleton'}-${i}`}>
          {renderItem ? renderItem(SkeletonComp()) : SkeletonComp()}
        </Fragment>
      ))}
    </>
  );
};

export default MercurySkeleton;
