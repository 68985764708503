import { forwardRef } from 'react';
import type {
  DetailedHTMLProps,
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  Ref,
} from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps extends
  DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>
  & AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLButtonElement | HTMLAnchorElement
  > {
  background?: string;
  color?: string;
  size?: number;
  onClick?: VoidFunction;
  small?: boolean;
  href?: string;
  icon?: ReactNode;
  asLink?: boolean;
}

const ButtonBase = styled.button<{
  $background: string;
  $color: string;
  $size: number;
  $small?: boolean;
}>`
  padding: 5px 15px;
  min-width: 100%;
  display: inline-block;
  background-color: ${(props) => props.$background};
  color: ${(props) => props.$color};
  font-size: ${(props) => props.$size}px;
  border-radius: var(--border-radius);
  text-align: center;
  height: 35px;

  @media (min-width: ${(props) => props.theme.breakpoints.S}) {
    min-width: 100px;
  }

  &:disabled {
    opacity: 0.5;
  }

  svg {
    fill: white;
  }
`;

const smallButton = css`
  font-size: 14px;
  padding: 5px 10px;
`;

const iconButton = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const ButtonStyled = styled(ButtonBase) <{
  $background: string;
  $color: string;
  $size: number;
  $small?: boolean;
  $hasIcon: boolean;
}>`
  ${(props) => props.$small && smallButton};
  ${(props) => props.$hasIcon && iconButton};
`;

const Button = forwardRef((
  {
    children,
    type = 'button',
    background,
    color,
    size,
    onClick,
    small,
    disabled,
    id,
    icon,
    asLink,
    href,
    target,
  }: PropsWithChildren<ButtonProps>,
  ref: Ref<HTMLButtonElement>,
) => {
  const props = asLink ? { href, target: target ?? '' } : { disabled, type };

  return (
    <ButtonStyled
      $background={background || 'var(--quo-blue-75)'}
      $color={color || 'var(--quo-white)'}
      $size={size || 16}
      $hasIcon={typeof icon !== 'undefined'}
      onClick={onClick}
      $small={small}
      id={id}
      ref={ref}
      as={`${asLink ? 'a' : 'button'}`}
      {...props}
    >
      {children}
      {icon}
    </ButtonStyled>
  );
});

export default Button;
