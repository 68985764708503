import { useAuth } from '@/hooks/useAuth';
import { isUndefined } from '@/utils';

import type { NormalizedRedmineField } from '../catalogs/types';

interface RiskIndicatorsProps {
  context: NormalizedRedmineField;
  indicator: 'relevant_technologies' | 'relevant_locations' | 'relevant_industry_sectors';
}

export const useRiskIndicator = ({
  context,
  indicator,
}: RiskIndicatorsProps) => {
  const auth = useAuth();

  const userContext = auth.userInfo[indicator];
  const relevantContext = userContext
    ? context.filter((item) => !isUndefined(userContext[item.id])).map(({ name }) => name)
    : [];

  return {
    hasIndicator: relevantContext.length > 0,
    relevantContext,
  };
};
