import {
  PropsWithChildren, ReactNode, createContext, useMemo, useState,
} from 'react';
import { DrawerProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import Drawer from '@/components/content/drawer/Drawer';

interface DrawerContextValue {
  openDrawer: (options: MercuryDrawerProps) => void;
  opened: boolean;
  drawerid: any;
}

type MercuryDrawerProps = Omit<DrawerProps, 'title' | 'opened' | 'onClose'> & {
  title?: string;
  drawerid: any;
  link?: ReactNode;
};

export const DrawerContext = createContext<DrawerContextValue | null>(null);

export const DrawerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [opened, { close, open }] = useDisclosure(false);
  const [drawerState, setDrawerState] = useState<MercuryDrawerProps>();

  const openDrawer = (options: MercuryDrawerProps) => {
    open();

    if (options) {
      setDrawerState(options);
    }
  };

  const closeDrawer = () => {
    close();

    setDrawerState((state) => ({
      ...state,
      title: '',
      drawerid: null,
    }));
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = useMemo(
    () => ({
      openDrawer,
      drawerid: drawerState?.drawerid,
      opened,
    }),
    [opened, drawerState?.id, drawerState?.children],
  );

  return (
    <DrawerContext.Provider value={value}>
      <Drawer opened={opened} onClose={closeDrawer} {...drawerState} />
      {children}
    </DrawerContext.Provider>
  );
};
