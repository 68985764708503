// TODO: FIx this eslint issue
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';

// Types
import type { Input as InputProps } from '@/types/forms';

import { Error, InputWrapperStyled, Label, LabelWrapper } from '../InputWrapper';
import FileInputSection from './FileInputSection';

const FileInput = styled.input`
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
`;

const Info = styled.span`
  font-size: 12px;
  color: var(--quo-grey-75);
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Input = <TFormValues extends Record<string, unknown>>({
  register,
  formOptions = {},
  label,
  watch,
  required,
  layout = 'block',
  error,
  info,
  resetField,
  displayError,
  extension,
  ...inputProps
}: Omit<InputProps<TFormValues>, 'type'>) => {
  const value = watch && watch(inputProps.name);
  const aria = info ? { 'aria-describedby': `addl-${inputProps.name}` } : {};

  const onClear = () => {
    if (resetField) {
      resetField(inputProps.name);
    }
  };

  return (
    <InputWrapperStyled $layout={layout} $displayInput>
      <LabelWrapper $layout={layout}>
        <Label>{label}</Label>
      </LabelWrapper>

      <div style={{ width: '100%' }}>
        <FileInput
          {...register(inputProps.name, formOptions)}
          id={inputProps.name}
          aria-required={required ? 'true' : 'false'}
          aria-invalid={error ? 'true' : 'false'}
          type="file"
          multiple
          {...aria}
          {...inputProps}
        />

        <FileInputSection onClear={onClear} name={inputProps.name} value={value} extension={extension} />

        {info && <Info id={`addl-${inputProps.name}`}>{info}</Info>}

        {displayError && error && <Error>{error.message}</Error>}
      </div>
    </InputWrapperStyled>
  );
};

export default Input;
