import type { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { useDocumentTitle } from '@mantine/hooks';

// Components
import Permission, { PermissionsProps } from './Permissions';
import Title from '../content/title/Title';

import { MERCURY_DOCUMENT_TITLE } from '@/constants';
import classes from './Layout.module.css';

export interface PageProps extends PermissionsProps {
  title?: string;
  documentTitle?: string;
  withOutlet?: boolean;
}

const Page = ({
  children,
  title,
  documentTitle,
  withOutlet = false,
  ...permissions
}: PropsWithChildren<PageProps>) => {
  useDocumentTitle(MERCURY_DOCUMENT_TITLE(documentTitle || title || 'App'));

  return (
    <Permission {...permissions}>
      {title && <div className={classes.pageTitle}><Title as="h1">{title}</Title></div>}
      {children}
      {withOutlet ? <Outlet /> : undefined}
    </Permission>
  );
};

export default Page;
