import { useSelect } from './SelectContext';
import { ComboboxMenuContainer, ComboboxMenu } from './multiselect.styles';
import HeaderItem from './HeaderItem';
import Item from './Item';

const Menu = () => {
  const {
    combobox: { getMenuProps, isOpen, highlightedIndex, getItemProps, inputValue },
    itemsStore: { mapSelectList, isDisabled, isSelected, isColapsed, toggleColapse },
  } = useSelect();

  return (
    <ComboboxMenuContainer>
      <ComboboxMenu {...getMenuProps()} hidden={!isOpen}>
        {isOpen
          && mapSelectList((item, itemIndex) => {
            const selected = isSelected(item.value);
            const disabled = isDisabled(item.value);
            const colapsed = isColapsed(item.value) && !inputValue;
            const itemProps = getItemProps({ item, index: itemIndex, 'aria-selected': selected });

            if (item.isHeader) {
              return (
                <HeaderItem
                  key={`comboboxitem-header-${item.value}`}
                  item={item}
                  itemProps={itemProps}
                  highlighted={highlightedIndex === itemIndex}
                  disabled={disabled}
                  selected={selected}
                  colapsed={colapsed}
                  toggleColapse={toggleColapse}
                />
              );
            }

            return !colapsed ? (
              <Item
                key={`comboboxitem-${item.value}`}
                item={item}
                itemProps={itemProps}
                highlighted={highlightedIndex === itemIndex}
                disabled={disabled}
                selected={selected}
                colapsed={colapsed}
              />
            ) : null;
          })}
      </ComboboxMenu>
    </ComboboxMenuContainer>
  );
};

export default Menu;
