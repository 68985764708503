import type { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

interface TitleProps {
  as?: keyof JSX.IntrinsicElements;
  button?: ReactNode
  id?: string
}

export const TitleStyled = styled.h1`
  word-break: break-word;

  &:not(:first-child) {
    margin-top: 25px;
  }

  h1& {
    font-size: 32px;
    line-height: 40px;
  }

  h2& {
    font-size: 24px;
    color: var(--title-color);
    line-height: 28px;
  }

  h3& {
    font-size: 18px;
    display: block;
    line-height: 22px;
  }

  h4& {
    font-weight: bold;
  }

  span {
    display: block;
  }

  small {
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
    display: block;
  }

  button {
    margin-top: 15px;

    @media (min-width: ${(props) => props.theme.breakpoints.S}) {
      margin-top: 0;
      margin-left: 15px;
    }
  }

  b {
    font-weight: bold;
    color: var(--quo-power-75)
  }
`;

export const TitleWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.S}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Title = ({ children, button, id, as = 'h2' }: PropsWithChildren<TitleProps>) => (
  <TitleStyled as={as} id={id}>
    {children}
    {button}
  </TitleStyled>
);

export default Title;
