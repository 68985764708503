// Notifications
type Notification = {
  [key: string]: number;
  total: number;
  unread: number;
  unacknowledged: number;
};

export enum NotificationsType {
  INTEL_FINISHED = 'intel_finished',
  INTEL_RAW = 'intel_raw',
  VULNERABILITIES = 'vulnerabilities',
  DRP_ALERTS = 'drp_alerts',
  DRP_TAKEDOWNS = 'drp_takedowns',
  RFI = 'rfi',
}

export interface NotificationsData {
  [key: string]: Notification;
  drp_alerts: Notification;
  drp_takedowns: Notification;
  intel_finished: Notification;
  intel_raw: Notification;
  vulnerabilities: Notification;
  // rfi: Omit<Notification, 'unacknowledged'>;
}
