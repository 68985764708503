import { NEWS } from '@/constants/text/english';

export const hasUnreadItems = () => {
  const forceHide = window.localStorage.getItem('isPlaywright');
  const lastRead = localStorage.getItem('news.lastRead');

  return !forceHide && NEWS[0] && NEWS[0].date !== lastRead;
};

export const setReadItems = () => {
  localStorage.setItem('news.lastRead', NEWS[0] && NEWS[0].date);
};
