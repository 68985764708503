export const ACCESS_ROLES = {
  ROLE_ANALYST: 'ROLE_ANALYST',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_CLIENT_ANALYST: 'ROLE_CLIENT_ANALYST',
  ROLE_CLIENT_USER: 'ROLE_CLIENT_USER',
  ROLE_SERVICE_USER: 'ROLE_CLIENT_SERVICE',
} as const;

export const ACCESS_MODULES = {
  SERVICE_PREMIUM: 'SERVICE_PREMIUM',
  MODULE_ADVTHREATINT: 'MODULE_ADVTHREATINT',
  MODULE_IDENTITY_PANEL: 'MODULE_IDENTITY_PANEL',
  MODULE_ALERTS_MGMT: 'MODULE_ALERTS_MGMT',
  MODULE_CUSTOMREPORT: 'MODULE_CUSTOMREPORT',
  SERVICE_BASE: 'SERVICE_BASE',
  EASM: 'MODULE_EASM',
  ANALYTICS: 'MODULE_ANALYTICS',
} as const;

const MODULES = {
  intelligence: {
    modules: [ACCESS_MODULES.SERVICE_PREMIUM, ACCESS_MODULES.MODULE_ADVTHREATINT],
  },
  alertsmanagement: {
    roles: [ACCESS_ROLES.ROLE_ANALYST],
    modules: [ACCESS_MODULES.MODULE_ALERTS_MGMT],
  },
  ransomware: {
    modules: [ACCESS_MODULES.SERVICE_PREMIUM, ACCESS_MODULES.MODULE_ADVTHREATINT],
  },
  easm: {
    modules: [ACCESS_MODULES.EASM],
  },
  identitypanel: {
    modules: [ACCESS_MODULES.MODULE_IDENTITY_PANEL],
  },
  analyticsdashboard: {
    modules: [ACCESS_MODULES.ANALYTICS],
  },
} as const;

const CATALOGS = {
  attck: {
    modules: [ACCESS_MODULES.SERVICE_PREMIUM, ACCESS_MODULES.MODULE_ADVTHREATINT],
  },
  threatactors: {
    modules: [ACCESS_MODULES.SERVICE_PREMIUM, ACCESS_MODULES.MODULE_ADVTHREATINT],
  },
  malwarekits: {
    modules: [ACCESS_MODULES.SERVICE_PREMIUM, ACCESS_MODULES.MODULE_ADVTHREATINT],
  },
  sources: {
    modules: [ACCESS_MODULES.SERVICE_PREMIUM, ACCESS_MODULES.MODULE_ADVTHREATINT],
  },
  providers: {
    modules: [ACCESS_MODULES.SERVICE_PREMIUM, ACCESS_MODULES.MODULE_ADVTHREATINT],
  },
  personas: {
    modules: [ACCESS_MODULES.SERVICE_PREMIUM, ACCESS_MODULES.MODULE_ADVTHREATINT],
  },
  vulnerabilities: {
    modules: [ACCESS_MODULES.SERVICE_PREMIUM, ACCESS_MODULES.MODULE_ADVTHREATINT],
  },
} as const;

export { MODULES, CATALOGS };
