// Components
import Title from '@/components/content/title/Title';

// Misc
import { RichText } from '@/components/content/markdown/Markdown';
import { sanitize } from '@/utils/sanitize';

import classes from './News.module.css';

interface NewsItemProps {
  date?: string;
  title: string;
  content: string;
}

const News = ({ newsItem }: { newsItem: NewsItemProps }) => (
  <div className={classes.wrapper}>
    <Title as="h3">
      <span className={classes.title}>{newsItem.title}</span>
      {newsItem.date && <span className={classes.muted}>{newsItem.date}</span>}
    </Title>

    <RichText dangerouslySetInnerHTML={{
      __html: sanitize(newsItem.content, {
        ALLOWED_URI_REGEXP: /^(?:(?:mailto):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i,
      }),
    }}
    />
  </div>
);

export default News;
