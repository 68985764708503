import { InvalidateQueryFilters, QueryKey, useQueryClient } from '@tanstack/react-query';

export const useQueryActions = () => {
  const queryClient = useQueryClient();

  const invalidateQuery = (queryKey: QueryKey, filters?: InvalidateQueryFilters) => (
    queryClient.invalidateQueries({ queryKey, ...filters })
  );

  const resetQuery = (queryKey: QueryKey, filters?: InvalidateQueryFilters) => (
    queryClient.resetQueries({ queryKey, ...filters })
  );

  const resetQueries = (queryKey: Array<QueryKey>, filters?: InvalidateQueryFilters) => (
    Promise.all(queryKey.map((qk) => resetQuery(qk, filters)))
  );

  const invalidateQueries = (queryKey: Array<QueryKey>, filters: InvalidateQueryFilters = {}) => (
    Promise.all(queryKey.map((qk) => invalidateQuery(qk, filters)))
  );

  const getData = <TData = unknown>(queryKey: QueryKey) => queryClient.getQueryData<TData>(queryKey);

  const setData = <TData = unknown>(queryKey: QueryKey, cb: (input: TData | undefined) => TData | undefined) => {
    queryClient.setQueriesData<TData>({ queryKey, exact: false }, (old) => cb(old));
  };

  const clear = () => queryClient.clear();

  const onMutate = <TData extends unknown>(queryKey: QueryKey, cb: (input: TData | undefined) => TData | undefined) => {
    // Snapshot the previous value
    const previousData = getData<TData>(queryKey);

    // Optimistically update to the new value
    setData<TData>(queryKey, cb);

    // Return a context object with the snapshotted value
    return { previousData };
  };

  return {
    clear,
    setData,
    getData,
    onMutate,
    resetQuery,
    resetQueries,
    invalidateQuery,
    invalidateQueries,
  };
};
