export type SupportFormFields = {
  subject: string;
  description: string;
  due_date: string;
  attachment: any;
  to: string;
  ticket_id: string;
  subclient_id: string;
};

export const SupportFormLabels = {
  subject: 'Subject',
  description: 'Description',
  dueDate: 'Due Date',
  attachment: 'Attached Files',
  subclient: 'Subclient',
} as const;
