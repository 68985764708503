import { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Menu } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import type { RoutePathDefinition } from '@/types/routes';
import { mapRouteswithChildren } from './mapRouteswithChildren';

import classes from './Navbar.module.css';

const SubMenuChild = ({ item, sideBarExpanded }: { item: RoutePathDefinition; sideBarExpanded: boolean }) => {
  const { icon: NavIcon, name, path, children } = item;
  const matches = useMediaQuery('(min-width: 62em)');
  const location = useLocation();

  const match = matchPath({ path, end: false }, location.pathname);
  const matchChild = useMemo(
    () => Boolean(
      (children ?? [])
        .map((child) => new URL(child.path, window.location.origin))
        .find((child) => matchPath({ path: child.pathname, end: false }, location.pathname)),
    ),
    [children, location],
  );

  if (!matches) {
    return (
      <div className={classes.submenuListWrapper}>
        <ul className={`${classes.submenuList}`}>
          {children && children.map((child) => mapRouteswithChildren({
            child,
            sideBarExpanded,
            showTooltip: true,
          }))}
        </ul>
      </div>
    );
  }

  return (
    <Menu
      trigger="hover"
      position="right"
      classNames={{ dropdown: classes.submenuDropdown }}
      offset={sideBarExpanded ? 30 : -160}
      withArrow
    >
      <Menu.Target>
        <li className={classes.submenuItem}>
          <span className={`${classes.link} ${classes.submenu} ${match !== null || matchChild ? classes.active : ''}`}>
            <span className={`${classes.linkIcon}`}>
              {NavIcon && <NavIcon />}
            </span>

            <span className={classes.linkLabel}>{name}</span>
          </span>
        </li>
      </Menu.Target>

      <Menu.Dropdown>
        <ul className={`${classes.submenuList}`}>
          {children && children.map((child) => mapRouteswithChildren({
            child,
            sideBarExpanded: false,
            showTooltip: false,
          }))}
        </ul>
      </Menu.Dropdown>
    </Menu>
  );
};

export default SubMenuChild;
