import styled, { css } from 'styled-components';

import type { ItemRenderProps } from './types';

const ItemBase = styled.li<{ $padding: string }>`
  padding: ${(props) => props.$padding};
  cursor: pointer;
`;

const selectedStyle = css`
  background: var(--menu-item-selected);
  color: var(--quo-grey);
`;

const highlightedStyle = css`
  background: var(--menu-item-highlight);
  color: var(--quo-grey);
`;

const disabledStyle = css`
  opacity: 0.4;
  cursor: not-allowed;
`;

const parentStyle = css`
  font-weight: bold;
  padding-left: 33px;
`;

const ItemStyled = styled(ItemBase)<{
  $selected: boolean;
  $highlighted: boolean;
  $disabled: boolean;
  $parent: boolean;
}>`
  ${(props) => props.$selected && selectedStyle};
  ${(props) => props.$highlighted && highlightedStyle};
  ${(props) => props.$disabled && disabledStyle};
  ${(props) => props.$parent && parentStyle};
`;

const Item = ({ item, highlighted, selected, disabled, itemProps }: Omit<ItemRenderProps, 'toggleColapse'>) => (
  <ItemStyled
    $padding={!item.depth ? '8px' : '8px 8px 8px 48px'}
    $selected={selected}
    $highlighted={highlighted}
    $disabled={disabled}
    $parent={item.depth === 1}
    {...itemProps}
  >
    {item.label}
  </ItemStyled>
);

export default Item;
