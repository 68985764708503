import { useEffect } from 'react';
import { type UseQueryOptions, useQueryClient } from '@tanstack/react-query';

// import { useAuth } from '@/hooks/useAuth';
import { useAuth } from '@/hooks/useAuth';

import type { UserId } from '@/types/api';

const Priority = ({ queries }: { queries: Array<(userId: UserId) => Omit<UseQueryOptions, 'select'>> }) => {
  const queryClient = useQueryClient();
  const { userInfo, isSuccess } = useAuth();

  useEffect(() => {
    if (isSuccess) {
      queries.forEach(async (query) => {
        queryClient.prefetchQuery(query(userInfo.user_id));
      });
    }
  }, [isSuccess]);

  return (
    null
  );
};

export default Priority;
