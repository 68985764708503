import { FilterProps } from '../../types';
import Checkbox from './Checkbox';
import ComboboxFilter from './Combobox';

import type { Multiple } from '@/types/meta';

const MultiSelect = ({ ...props }: FilterProps<Multiple, number[] | number | string>) => {
  if (props.filterMeta.layout === 'checkbox') {
    return <Checkbox {...props} />;
  }

  return <ComboboxFilter {...props} />;
};

export default MultiSelect;
