/* eslint-disable jsx-a11y/label-has-associated-control */
import styled, { css } from 'styled-components';
import type { FieldError } from 'react-hook-form';
import type { Textarea as TextAreaProps } from '@/types/forms';

// Components
import InputWrapper from './InputWrapper';
import { Info } from './Input';

const TextAreaBase = styled.textarea`
  width: 100%;
  outline: none;
  font-size: 14px;
  color: var(--content-font);
  border: 1px solid var(--default-border);
  border-radius: 4px;
  resize: vertical;
  padding: 5px 3px 5px 12px;
`;

const errorStyle = css`
  border-bottom: 3px solid var(--quo-power-75);
`;

const TextAreaStyled = styled(TextAreaBase) <{ $error?: FieldError }>`
  ${(props) => props.$error && errorStyle}
`;

const TextArea = <TFormValues extends Record<string, unknown>>({
  register,
  formOptions = {},
  label,
  required,
  layout,
  error,
  info,
  ...inputProps
}: TextAreaProps<TFormValues>) => (
  <InputWrapper label={{ for: inputProps.name, text: label, required }} layout={layout}>
    <div style={{ width: '100%' }}>
      <TextAreaStyled
        {...register(inputProps.name, formOptions)}
        {...inputProps}
        rows={5}
        id={inputProps.name}
        aria-required={required ? 'true' : 'false'}
        aria-invalid={error ? 'true' : 'false'}
        $error={error}
        {...inputProps}
      />
      {info && <Info id={`addl-${inputProps.name}`}>{info}</Info>}
    </div>
  </InputWrapper>
  );

export default TextArea;
