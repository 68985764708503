import type { ReactNode, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import type { FieldError } from 'react-hook-form';

interface InputWrapperProps {
  layout?: string;
  display?: boolean;
  label: {
    for: string;
    text?: string;
    required?: boolean;
  };
  displayError?: boolean;
  error?: FieldError;
  link?: ReactNode;
}

type Layout = { $layout: string; $displayInput?: boolean };

export const InputWrapperStyled = styled.div<Layout>`
  position: relative;
  align-items: center;
  display: ${(props) => (props.$displayInput ? 'block' : 'none')};

  @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    display: ${(props) => (props.$displayInput ? props.$layout : 'none')};
  }
`;

const LabelWrapperBase = styled.div<Layout>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & ~ input,
  & ~ div,
  & ~ textarea,
  & ~ select {
    margin-top: 5px;

    @media (min-width: ${(props) => props.theme.breakpoints.M}) {
      margin-top: 0;
    }
  }

  a {
    font-size: 12px;
  }
`;

const labelWrapperFlex = css`
  @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    flex: 40%;
  }
`;

export const LabelWrapper = styled(LabelWrapperBase)<Layout>`
  ${(props) => props.$layout === 'flex' && labelWrapperFlex};
`;

export const Label = styled.label`
  font-size: 14px;
  margin-right: 10px;
`;

const Sup = styled.sup`
  color: var(--quo-power);
`;

export const Error = styled.span`
  font-size: 14px;
  color: var(--active-color);
`;

const InputWrapper = ({
  children,
  label,
  display,
  layout = 'block',
  displayError,
  error,
  link,
}: PropsWithChildren<InputWrapperProps>) => {
  const displayInput = typeof display === 'undefined' ? true : display;

  return (
    <InputWrapperStyled className="form__fieldWrapper" $layout={layout} $displayInput={displayInput}>
      {label.text && (
        <LabelWrapper $layout={layout}>
          <Label id={`label-${label.for}`} htmlFor={label.for}>
            {label.text}
            {label.required && <Sup>*</Sup>}
          </Label>

          {displayError && error && <Error role="alert">{error.message}</Error>}

          {link && link}
        </LabelWrapper>
      )}

      {children}
    </InputWrapperStyled>
  );
};

export default InputWrapper;
