import styled, { css } from 'styled-components';

// Types
import type { CheckboxProps } from '@/types/forms';
import { Info } from './Input';
import InputWrapper from './InputWrapper';

// Styles
export const CheckboxFlexWrapper = styled.label<{ color: string }>`
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  color: ${(props) => props.color};
  text-transform: capitalize;

  @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    margin-top: 0;
  }


`;

export const CheckboxStyled = styled.input`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  accent-color: var(--quo-blue-75);
`;

const CheckboxBase = styled.div`
  width: 100%;
`;

const checkboxFlex = css`
  @media (min-width: ${(props) => props.theme.breakpoints.M}) {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
`;

export const CheckboxGroupWrapper = styled(CheckboxBase) <{ $layout?: string }>`
  ${(props) => props.$layout && checkboxFlex};
`;

const Checkbox = <TFormValues extends Record<string, unknown>>({
  register,
  formOptions = {},
  label,
  itemLabel,
  required,
  error,
  single,
  group,
  items,
  layout,
  disabled,
  info,
  ...inputProps
}: CheckboxProps<TFormValues>) => (
  <InputWrapper label={{ for: inputProps.name, text: label, required }} layout={layout}>
    {group && items && (
      <CheckboxGroupWrapper $layout="flex">
        {
          items.map((item: { label: string, value: string }, index: number) => (
            <CheckboxFlexWrapper key={item.value} color={error ? 'var(--quo-power)' : 'inherit'}>
              <CheckboxStyled
                {...register(inputProps.name, formOptions)}
                {...inputProps}
                id={`${inputProps.name}-${index}`}
                aria-invalid={error ? 'true' : 'false'}
                type="checkbox"
                disabled={disabled}
                value={item.value}
                {...inputProps}
              />

              {item.label || item.value}
            </CheckboxFlexWrapper>
          ))
        }
      </CheckboxGroupWrapper>
    )}

    {single && (
      <CheckboxBase>
        <CheckboxFlexWrapper color={error ? 'var(--quo-power)' : 'inherit'}>
          <CheckboxStyled
            {...register(inputProps.name, formOptions)}
            {...inputProps}
            id={inputProps.name}
            aria-required={required ? 'true' : 'false'}
            aria-invalid={error ? 'true' : 'false'}
            type="checkbox"
            disabled={disabled}
          />

          {itemLabel}
        </CheckboxFlexWrapper>
        {info && <Info id={`addl-${inputProps.name}`}>{info}</Info>}
      </CheckboxBase>
    )}
  </InputWrapper>
  );

export default Checkbox;
